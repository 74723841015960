<template>
    <div>
        <el-dialog title="转换预算类型" :visible="editBuygetTypeShow" width="1200px" @close="close">
            <div v-loading="loading">
                <el-form :model="adInfo" ref="ruleForm" label-width="140px" class="demo-ruleForm">
                    <el-form-item label="广告系列预算优化" prop="name">
                        <el-switch v-model="adInfo.is_cbo" disabled></el-switch>
                        <p class="tips">
                            {{adInfo.is_cbo?testList[0]:testList[1]}}
                        </p>
                    </el-form-item>
                </el-form>
                <el-form :model="adSet" ref="ruleForm" label-width="140px" class="demo-ruleForm" v-if="adInfo.is_cbo">
                    <el-form-item label="广告系列预算" class="blod">
                        <el-input v-model="adSet.budget">
                            <el-select v-model="adSet.budget_type" slot="prepend" placeholder="请选择" disabled>
                                <el-option label="单日预算" value="daily"></el-option>
                                <el-option label="总预算" value="lifetime"></el-option>
                            </el-select>
                            <el-button slot="append">{{currencyName}}</el-button>
                        </el-input>
                        <p class="tips" style="margin-top:10px;">
                            每日花费的实际金额可能不同
                        </p>
                    </el-form-item>
                </el-form>
                <div class="adset"  v-if="!adInfo.is_cbo">
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 96%" @selection-change="handleSelectionChange" border max-height="400px">
                        <el-table-column type="selection" width="55"> </el-table-column>
                        <el-table-column label="广告组名称" width="120">
                            <template slot-scope="scope">{{ scope.row.name }}</template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="120"> </el-table-column>
                        <el-table-column prop="spend" label="花费金额" width="80"> </el-table-column>
                        <el-table-column prop="address" label="预算" width="280">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.budget" @input="upadteBudget(scope.row,scope.row.budget,scope.$index,'budget')">
                                    <el-select v-model="scope.row.budget_type" slot="prepend" placeholder="请选择" disabled>
                                        <el-option label="单日预算" value="daily"></el-option>
                                        <el-option label="总预算" value="lifetime"></el-option>
                                    </el-select>
                                    <el-button slot="append">{{currencyName}}</el-button>
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="竞价策略" width="300px">
                            <template slot-scope="scope">
                                <el-radio-group v-model="scope.row.bidStrategy"  @change="upadteBudget(scope.row,scope.row.bidStrategy,scope.$index,'bidStrategy')">
                                     <el-radio style="margin-right:10px" :label="item.key" v-for="(item,i) in initData.ad.CampaignBidStrategy" :key="i">{{item.desc}}</el-radio>
                                </el-radio-group>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="竞价金额" width="158">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.bidAmount" @input="upadteBudget(scope.row,scope.row.bidAmount,scope.$index,'bidAmount')"  v-if="(scope.row.bidStrategy != 'LOWEST_COST_WITHOUT_CAP')">
                                    <el-button slot="append">{{currencyName}}</el-button>
                                </el-input>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="text-align:right;margin-top:10px">
                        <p>已发布广告组的单日总预算：{{currencyName}}{{activeMoney.activeMoney}}</p>
                        <p>已关闭广告组的单日总预算：{{currencyName}}{{activeMoney.pausedMoney}}</p>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="sure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import {report,budgetOptimization} from "@/api/adManagement";
    import { mapState, mapGetters } from "vuex";
    export default {
        props:['editBuygetTypeShow','row','is_cbo','textList'],
        data() {
            return {
                adInfo:{
                    is_cbo:false,
                },
                testList:[],
                adSet:{
                    budget:10,
                    budget_type:'daily'
                },
                tableData: [],
                params: {
                    account_id: this.row.rowAccountId || this.$store.getters.accountId,
                    action_attribution_windows:  [],
                    after: '',
                    date_end: '',
                    date_start: '',
                    fields: ['budget','id','name','status','spend','bid_amount','bid_strategy'],
                    filtering: [],
                    object_filtering: [{field:'campaign.id',operator:'IN',value:[this.row.id]}],
                    keyword: '',
                    level: 'adset',
                    page_length: 100,
                    sort: [],
                },
                loading:false
            }
        },
        computed:{
            currencyName(){
                return this.$store.getters?.currentUiUnit?.symbol || '$';
            },
            showMoney(v){
                return this.$store.getters.toFormatedMoney(v).formated;
            },
            activeMoney(){
                let budget1 = 0,budget2=0;
                this.tableData.map(item=>{
                    if(item.status == 'ACTIVE'){
                        budget1 += item.budget/1;
                    }else if(item.status == 'PAUSED'){
                        budget2 += item.budget/1
                    }
                    
                });
                return {
                    activeMoney:budget1,
                    pausedMoney:budget2
                };
            },
            ...mapState("initData", ["initData"]),
        },
        methods: {
            upadteBudget(row,v,index,key){
                console.log(row,key,v);
                this.$set(row,key,v);
                this.$set(this.tableData,index,row)
                this.$forceUpdate();
            },
            close(){
                this.$emit('update:editBuygetTypeShow',false)
                this.$emit('update:is_cbo',!this.is_cbo)
            },
            sure(){
                // this.close()
                // 广告组转广告系列预算
                let params = {
                    isCampaign2AdSet:!this.adInfo.is_cbo,
                    campaignBudget:{
                        campaignId:this.row.id,
                    },
                    adSetBudgets:[]
                }
                let type = this.adSet.budget_type == 'daily'?'dailyBudget':'lifetimeBudget';
                let isDailyBudget = this.adSet.budget_type == 'daily'?true:false;
                // 广告组转广告系列
                if(this.adInfo.is_cbo){
                    params.campaignBudget.isDailyBudget = isDailyBudget;
                    if(this.adSet.budget<4){
                            this.$message({
                                type:'warning',
                                message:'预算至少为$4'
                            })
                            return false;
                        }
                    params.campaignBudget[type] = this.$store.getters.toBaseMoney(this.adSet.budget)
                    
                }else{
                    // 广告系列预算转广告组预算
                    let flag = true
                    this.tableData.map(v=>{
                        if(v.budget<1){
                            this.$message({
                                type:'warning',
                                message:'预算至少为$1'
                            })
                            flag = false;
                        }
                        if(v.bidStrategy !== 'LOWEST_COST_WITHOUT_CAP' && !v.bidAmount){
                            this.$message({
                                type:'warning',
                                message:'请设置竞价金额'
                            })
                            flag = false;
                        }
                        let obj = {};
                        obj[type] = this.$store.getters.toBaseMoney(v.budget);
                        obj.isDailyBudget = isDailyBudget,
                        obj.adSetId = v.id;
                        obj.bidStrategy = v.bidStrategy;
                        obj.bidAmount = v.bidAmount?this.$store.getters.toBaseMoney(v.bidAmount):null
                        params.adSetBudgets.push(obj)
                    })
                    if(!flag){
                        return false;
                    }
                }
                this.$showLoading();
                budgetOptimization(params,this.row.rowAccountId).then(res=>{
                    this.$hideLoading();
                    if(res.code == 0){
                        this.$message({
                            type:'success',
                            message:res.comment
                        })
                        this.$emit('refreshTable');
                        this.$emit('update:editBuygetTypeShow',false);
                        this.$emit('update:is_cbo',!this.is_cbo)
                    }
                })
                console.log(params);
            },
            handleSelectionChange(){

            },
            getList(){
                if(this.row.id && this.editBuygetTypeShow){
                    this.loading = true;
                    report(JSON.stringify(this.params),this,this.row.rowThirdNum).then(res => {
                        this.loading = false;
                        if(res.code == 0){
                            this.tableData = JSON.parse(JSON.stringify(res.data.data));
                            this.tableData.map((item,index)=>{
                                item.budget =(this.adSet.budget/this.tableData.length).toFixed(2);
                                item.budget_type = this.adSet.budget_type;
                                item.spend = item.spend?this.currencyName+''+item.spend:'--';
                                item.bidStrategy = item.bid_strategy || 'LOWEST_COST_WITHOUT_CAP'
                                item.bidAmount = ''
                            })
                        }
                        this.$forceUpdate();
                    })
                }
            },
            setDateRangeParam(){
                // console.log('dateRange',this.dateRange);
                this.params.action_attribution_windows = this.dateRange.actionAttributionWindows;
                this.params.date_start = this.dateRange.dateRange[0];
                this.params.date_end = this.dateRange.dateRange[1];
                if(!this.adInfo.is_cbo){
                    this.getList();
                }
                
            },
        },
        watch:{
            editBuygetTypeShow:{
                handler(){
                    console.log(1);
                    this.adInfo.is_cbo = this.is_cbo;
                    this.testList = this.textList;
                    if(localStorage.getItem('adDateRange')){
                        this.dateRange = {...JSON.parse(localStorage.getItem('adDateRange'))}
                    }
                    if(this.row.daily_budget){
                        this.adSet.budget_type = 'daily'
                        this.adSet.budget = this.$store.getters.toFormatedMoney(this.row.daily_budget).value;
                    }else if(this.row.lifetime_budget){
                        this.adSet.budget_type = 'lifetime'
                        this.adSet.budget = this.$store.getters.toFormatedMoney(this.row.lifetime_budget).value
                    }
                    this.setDateRangeParam()
                },
                deep:true
            },
        }
    }
</script>
<style lang="scss" scope>
    .el-input-group__prepend{
        width: 110px;
    }
    .el-form{
        .el-input-group{
            width: 80%;
        }
    }
    .tips{
        font-size: 12px;
        color: #999;
        line-height: 1.5;
    }
</style>